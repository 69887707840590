import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import '../assets/style.css';
import {Card, Col, Container, Row, Table} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {SYMBOL_ROUTE} from "../utils/consts";

const SignalsList = observer(() => {
    const {signal} = useContext(Context)
    const history = useHistory()

    const timeAgoSignal = (time) => {
        let s = new Date(time)
        return (<span>{new Intl.DateTimeFormat('en-US',
            {month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: "h24"}
        ).format(s)}</span>)
    }

    if (signal.signals && Object.keys(signal.signals)) {
        //console.log('got signals', Object.keys(signal.signals))
        //console.log(signal.signals)
        return (
            <Col className="flex mt-3">
                {
                    /*
                      <Row>
                    <Col><b>Symbol</b></Col>
                    <Col><b>Exch</b></Col>
                    <Col className={'text-right'}><b>Vol. rate</b></Col>
                    <Col className={'text-right'}><b>Price dev.</b></Col>
                    <Col><b>Time</b></Col>
                </Row>
                     */
                }

                {
                    signal.signals.map((s) =>
                            <Row key={s.id} style={{
                                backgroundColor: s.id == signal.selectedSignal.id ? `rgba(0, 0, 0, 0.1)` : null,

                            }}>
                                <Col >
                                    <a
                                        onClick={() => {
                                            signal.setParams(['symbol', s.symbol])
                                            signal.setSelectedSymbol(s.symbol)
                                            signal.setSelectedSignal(s)
                                            history.push(SYMBOL_ROUTE + '/' + s.symbol)
                                            //console.log(s.id)
                                        }}
                                        className={'text-primary'}
                                        style={{cursor: 'pointer'}}
                                    >
                                        {

                                            s.symbol.slice(-4) == 'USDT'
                                                ?
                                                s.id == signal.selectedSignal.id ?
                                                    <b>
                                                        <span>{s.symbol.replace('USDT', '')}</span> <span><small>usdt</small></span>
                                                    </b>
                                                    :
                                                    <span>
                                                <span>{s.symbol.replace('USDT', '')}</span> <span><small>usdt</small></span>
                                            </span>
                                                : s.symbol
                                        }
                                    </a>
                                </Col>
                                <Col >
                                    <a target="_blank" rel="noreferrer" href={`https://binance.com/en/futures/${s.symbol}`}>
                                        <i className={'binance'}></i>
                                    </a>
                                    <a target="_blank" rel="noreferrer"
                                       href={`https://bybit.com/trade/usdt/${s.symbol}`}>
                                        <i className={'bybit'}></i>
                                    </a>
                                </Col>
                                <Col className={'text-right'}>
                                    {s.volumeRate.toFixed(1)}
                                </Col>
                                <Col className={'text-right'} style={{
                                    backgroundColor: s.deviationRate > 1 ? `rgba(95,180,255, ${(s.deviationRate - 1) * 0.2})` : null,
                                    color: s.open > s.close ? '#F6465D' : '#03A66D',
                                    fontWeight: s.deviationRate > 2 ? 'bold' : 'normal'
                                }}>
                                    {s.deviationRate.toFixed(1)}
                                </Col>
                                <Col >
                                    <small>{timeAgoSignal(s.timeClose)}</small>
                                </Col>

                            </Row>
                        //    : null
                    )
                }
                {/*
                    signal.signals.keys.map((item, i) =>
                    <Row key={item}>
                        <Col>
                            {item}
                        </Col>
                        <Col className={'text-center'}>
                            <span className={item.sell_cnt > 0 ? 'text-danger' : 'text-secondary'}>
                                {item.sell_cnt}
                            </span>
                            <span className={'text-secondary'}> / </span>
                            <span className={item.buy_cnt > 0 ? 'text-success' : 'text-secondary'}>
                                {item.buy_cnt}
                            </span>
                        </Col>
                        <Col>
                            <a target="_blank" rel="noreferrer" href={`https://www.bybit.com/trade/usdt/${item.symbol}`}>
                                Bybit
                            </a>&nbsp;
                            <a target="_blank" rel="noreferrer" href={`https://www.binance.com/en/futures/${item.symbol}`}>
                                Binance
                            </a>
                        </Col>
                        <Col>
                            {timeAgoSignal(item.timestamp)}
                        </Col>
                    </Row>
                )
                */}
            </Col>
        );
    } else {
        console.log(signal.signals)
        return (
            <Row className="d-flex mt-3 mb-5">
                No items
            </Row>
        );
    }
});

export default SignalsList;