import React, {useContext} from 'react';
import {Context} from "../index";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/logo.png"
import {
    FAQ_ROUTE,
    HOME_ROUTE,
    LOGIN_ROUTE,
    USER_ROUTE,
    SIGNALS_BY_VOLUME_ROUTE,
    NOTIFICATIONS_ROUTE, TRADE_STAT_ROUTE,
} from "../utils/consts";
import {Button} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {useHistory,useLocation} from 'react-router-dom'
import {Helmet} from "react-helmet";

const NavBar = observer(() => {
    const {user} = useContext(Context)
    const history = useHistory()
    const location = useLocation();
    const logOut = () => {
        user.setUser({})
        user.setIsAuth(false)
        localStorage.removeItem('token')
    }

    let ogTitle = 'Crypto signals, pump finder'
    let ogDescription = 'Find pump, dump and other crypto signals on whole market\n'
    let ogImage = 'img_top.png'
    //let wlp = window.location.pathname
    //let wlp = this.props.location.pathname
    let wlp = location.pathname
    return (
        <div>
            <Helmet>
                <title>{ogTitle}</title>
                <meta property="og:title" content={ogTitle} />
                <meta property="og:type" content="website" />
                <meta property="og:description" content={ogDescription} />
                <meta property="og:url" content={window.location.href} />
                <meta name="description" content={ogDescription} />
                <meta name="twitter:card" content={ogImage} />
                <meta name="twitter:title" content={ogTitle} />
                <meta name="twitter:description" content={ogDescription} />
                <meta name="twitter:image" content={ogImage} />
                <meta property="og:image" content={ogImage} />
                <meta property="og:site_name" content="Crypto pump" />
                <meta property="og:locale" content="en_US" />
            </Helmet>
            <Navbar bg="primary" variant="dark"  collapseOnSelect expand="sm" >
                <Navbar.Brand onClick={() => history.push(HOME_ROUTE)} style={{cursor:'pointer'}}>
                    <img src={logo} width="30" height="30" className="d-inline-block align-top" alt="Crypto pump finder"/>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav>
                        <Nav.Link onClick={() => history.push(SIGNALS_BY_VOLUME_ROUTE)}
                                  style={wlp.includes(SIGNALS_BY_VOLUME_ROUTE) ? {cursor:'pointer',color: '#fff'} : {cursor:'pointer',color: '#eee'}}
                        >Signals</Nav.Link>

                        <Nav.Link onClick={() => history.push(NOTIFICATIONS_ROUTE)}
                                  style={wlp.includes(NOTIFICATIONS_ROUTE) ? {cursor:'pointer',color: '#fff'} : {cursor:'pointer',color: '#eee'}}
                        >Notifications</Nav.Link>
                        <Nav.Link onClick={() => history.push(TRADE_STAT_ROUTE)}
                                  style={wlp.includes(TRADE_STAT_ROUTE) ? {cursor:'pointer',color: '#fff'} : {cursor:'pointer',color: '#eee'}}
                        >Trade stat</Nav.Link>

                        {
                            /*
                             <Nav.Link onClick={() => history.push(TWEETS_ROUTE)}
                                  style={wlp.includes(TWEETS_ROUTE) ? {cursor:'pointer',color: '#fff'} : {cursor:'pointer',color: '#eee'}}
                        >Tweets</Nav.Link>
                             */
                        }
                        <NavDropdown title="Info" id="collasible-nav-dropdown" className={""}>
                            <NavDropdown.Item href="https://crypto2pump.com/news/">News</NavDropdown.Item>
                            <NavDropdown.Item href="https://discord.gg/DVz9CKjAw3">Discord</NavDropdown.Item>
                            <NavDropdown.Item href="https://t.me/crypto_554">Telegram</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                {user.isAuth
                    ?
                    <Nav className={"ml-auto d-inline"}>
                        <Button variant={"outline-light"} onClick={() => history.push(USER_ROUTE)}
                        >
                            {user.info.name} <i className={"gold"}></i>
                        </Button>
                        <Nav.Link className="d-inline" onClick={() => logOut()}><i className={'icon-logout ml-1'}></i></Nav.Link>
                    </Nav>
                    :
                    <Nav className={"ml-auto"}>
                        <Button variant={"outline-light"} onClick={() => history.push(LOGIN_ROUTE)}>Login</Button>
                    </Nav>
                }
                <Navbar.Toggle className="ml-auto" aria-controls="responsive-navbar-nav" />
            </Navbar>
        </div>
    );
});

export default NavBar;