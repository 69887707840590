import React, {useContext, useEffect, useState} from 'react';
import {Button, Container, Form} from "react-bootstrap";
import Card from 'react-bootstrap/Card'
import {Context} from "../index";
import {notifications} from "../http/userAPI";
import Footer from "../components/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactGA from "react-ga4";
import {observer} from "mobx-react-lite";
import {useHistory} from 'react-router-dom'
import {values} from "mobx";
import SearchBarNotifications from "../components/SearchBarNotifications";
import NotificationsList from "../components/NotificationsList";


const NotificationPage = observer(() => {
        const history = useHistory()
        const {user} = useContext(Context)
        document.title = 'Real time notifications - Crypto signals, pump finder';

        useEffect(() => {
            notifications().then(data => user.setNotifications(data))
        }, [])

        return (
            <Container className="d-flex flex-column ">
                {
                    /*
                                  <Card className="mt-3 p-3">
                    <div className="mt-3 font-weight-bold">Notifications</div>
                    <div className="mt-3">Email: {user.info.email}</div>
                    {new Date(user.info.expDate) > new Date() ?
                        <div>
                            <div>Subscription: Standard</div>
                            <div>Expiration date: {new Date(user.info.expDate).toDateString()}</div>
                            <div>Days
                                left: {Math.round((new Date(user.info.expDate) - new Date()) / (24 * 60 * 60 * 1000))}</div>
                        </div>
                        :
                        <div>
                            <div>Subscription: None</div>
                        </div>
                    }
                </Card>
                     */
                }

                <SearchBarNotifications/>
                <NotificationsList/>
                <Footer/>
            </Container>
        );
    })
;

export default NotificationPage;
