import React, {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Button, Card, Col, Form, FormControl, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {LOGIN_ROUTE, USER_ROUTE} from "../utils/consts";
import InputGroup from "react-bootstrap/InputGroup";
import {delNotification, notifications, setNotification} from "../http/userAPI";

const NotificationsList = observer(() => {
    const {user} = useContext(Context)
    const deleteNotification = (id) => {
        delNotification(id).then(data => {
            if(data.success){
                setTimeout(function(){
                    notifications().then(data => user.setNotifications(data))
                }, 100)
            }
            else setErrorMsg(data.errorMsg)
        })
    }
    if(user.notifications.length ){
        //console.log(Object.keys(signal.signals))
        //options.scales.x.max = Date.now()
        //options.scales.x.min = Date.now() - 1000 * 60 * 20
        // <i className={'icon-trash ml-1'}></i>
        return (
            <Card className="flex">
                <Row>
                    <Col><b>Volume rate</b>, more than</Col>
                    <Col><b>Price deviation</b>, more than</Col>
                    <Col><b>Time frame</b></Col>
                    <Col></Col>
                </Row>
                {
                    user.notifications.map((n) =>
                    <Row key={n.id} className={"p-1"}>
                        <Col>
                            {n.volumeRate}
                        </Col>
                        <Col>
                            {n.deviationRate}
                        </Col>
                        <Col>
                            {n.timeFrame} min
                        </Col>
                        <Col>
                            <Button
                                variant="outline-danger"
                                //className="align-self-end m-3"
                                onClick={e => deleteNotification(e.target.value)}
                                value={n.id}
                            >
                                Del
                            </Button>
                        </Col>
                    </Row>
                )

                }
            </Card>
        );
    }
    else{
        //console.log(user.notifications)
        return (
            <div className="d-flex mt-3 mb-5">

            </div>
        );
    }
});

export default NotificationsList;


