import React, {useContext, useEffect, useState} from 'react';
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import {fetchSignals, fetchSignalsByVolume, fetchSymbols} from "../http/signalAPI";
import SignalsListByVolume from "../components/SignalsListByVolume";
import SearchBar from "../components/SearchBar";
import SymbolBar from "../components/SymbolBar";

const SignalsByVolumePage = observer(() => {
    const {user,signal} = useContext(Context)
    signal.setParams(['symbol', ''])

    useEffect(() => {
        //fetchSymbols().then(data => {signal.setSymbols(data)})
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchSignals(signal.params).then(data => {
                signal.setSignals(data)
            })
        }, 60000)
        return () => clearInterval(intervalId);
    }, [])

    useEffect(() => {
        fetchSignals(signal.params).then(data => {
            signal.setSignals(data)
        })
        fetchSymbols(signal.params).then(data => {signal.setSymbols(data)})
    }, [signal.params.volumeRate, signal.params.deviationRate, signal.params.priceChgDir, signal.params.timeFrame])


    let ogTitle = 'Signals list'
    let h1 = 'Signals list'
    let ogDescription = ''
    let ogImage = 'og_image1.png'

    return (
        <Container>
            <Helmet>
                <title>{ogTitle}</title>
                <meta name="description" content={ogDescription} />
                <meta name="twitter:card" content={ogImage} />
                <meta name="twitter:title" content={ogTitle} />
                <meta name="twitter:description" content={ogDescription} />
                <meta name="twitter:image" content={ogImage} />
                <meta property="og:title" content={ogTitle} />
                <meta property="og:description" content={ogDescription} />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:site_name" content="Crypto Analytics" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
            </Helmet>

            <h1 style={{fontSize: '18px'}} className={"mt-2"}>{h1}</h1>
            <SymbolBar/>
            <SearchBar/>
            <SignalsListByVolume/>
            <Footer/>
        </Container>
    );
});

export default SignalsByVolumePage;