import React, {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Button, Card, Col, Form, FormControl, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {LOGIN_ROUTE, USER_ROUTE} from "../utils/consts";
import InputGroup from "react-bootstrap/InputGroup";
import {notifications, setNotification} from "../http/userAPI";

const SearchBarNotifications = observer(() => {
    const {user, signal} = useContext(Context)
    const [volumeRate, setVolumeRate] = useState(2)
    const [deviationRate, setDeviationRate] = useState(1.5)
    const [timeFrame, setTimeFrame] = useState(1)
    const addNotification = () => {
        setNotification({volumeRate, deviationRate, timeFrame}).then(data => {
            if (data.success) {
                setTimeout(function () {
                    notifications().then(data => user.setNotifications(data))
                }, 100)
            } else setErrorMsg(data.errorMsg)
        })
    }
    return (
        <Card className="flex mt-3 mb-3 p-2">
            <Form className="flex">
                <Row className="">
                    <Col sm={5} className="mt-1">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="btnGroupVol">
                                    <OverlayTrigger
                                        overlay={<Tooltip id="tooltip-volume">Current volume / Average last hour
                                            volume</Tooltip>}>
                                        <span style={{
                                            cursor: 'help',
                                            textDecoration: "underline",
                                            textDecorationStyle: "dashed"
                                        }}>Vol. rate</span>
                                    </OverlayTrigger>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-describedby="btnGroupVol"
                                placeholder="Volume rate"
                                value={volumeRate}
                                //onChange={e => signal.setParams(['volumeRate', e.target.value])}
                                onChange={e => setVolumeRate(e.target.value)}
                                disabled={!user.isAuth || !user.info.isActivated || !user.info.tgConnected}
                                type={"number"}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={5} className="mt-1">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="btnGroupPrice">
                                    <OverlayTrigger
                                        overlay={<Tooltip id="tooltip-price">Price chg last minute / Price standard
                                            deviation last hour</Tooltip>}>
                                        <span style={{
                                            cursor: 'help',
                                            textDecoration: "underline",
                                            textDecorationStyle: "dashed"
                                        }}>Price dev.</span>
                                    </OverlayTrigger>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-describedby="btnGroupPrice"
                                placeholder="Price deviation rate"
                                value={deviationRate}
                                //onChange={e => signal.setParams(['deviationRate', e.target.value])}
                                onChange={e => setDeviationRate(e.target.value)}
                                disabled={!user.isAuth || !user.info.isActivated || !user.info.tgConnected}
                                type={"number"}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={5} className="mt-1">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="btnGroupDir">
                                    <OverlayTrigger
                                        overlay={<Tooltip id="tooltip-dir">Price change direction</Tooltip>}>
                                        <span style={{
                                            cursor: 'help',
                                            textDecoration: "underline",
                                            textDecorationStyle: "dashed"
                                        }}>Direction</span>
                                    </OverlayTrigger>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <select
                                className="form-control"
                                id="priceChgDir"
                                aria-describedby="btnGroupDir"
                                value={signal.params.priceChgDir}
                                //onChange={e => signal.setParams(['priceChgDir', e.target.value])}
                                disabled={!user.isAuth || !user.info.isActivated || !user.info.tgConnected}
                            >
                                <option>All</option>
                                <option>Up</option>
                                <option>Down</option>
                            </select>
                        </InputGroup>
                    </Col>
                    <Col sm={5} className="mt-1">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="btnGroupFrame">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-frame">Time frame</Tooltip>}>
                                        <span style={{
                                            cursor: 'help',
                                            textDecoration: "underline",
                                            textDecorationStyle: "dashed"
                                        }}>Time</span>
                                    </OverlayTrigger>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <select
                                className="form-control"
                                id="timeFrame"
                                aria-describedby="btnGroupFrame"
                                value={timeFrame}
                                //onChange={e => signal.setParams(['timeFrame', e.target.value])}
                                onChange={e => setTimeFrame(e.target.value)}
                                disabled={!user.isAuth || !user.info.isActivated || !user.info.tgConnected}
                            >
                                <option value={1}>1m</option>
                                <option value={5}>5m</option>
                                <option value={15}>15m</option>
                                <option value={30}>30m</option>
                                <option value={60}>1h</option>
                                <option value={240}>4h</option>
                            </select>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="d-flex font-weight-bold">
                    {!user.isAuth ?
                        <Col className="mt-3">
                            Please <NavLink to={LOGIN_ROUTE}>login</NavLink> to use real time notifications.
                        </Col>
                        :
                        !user.info.isActivated ?
                            <Col className="mt-3">
                                Please check email and activate your account to use real time notifications.
                            </Col>
                            :
                            !user.info.tgConnected ?
                                <Col className="mt-3">
                                    Please <NavLink to={USER_ROUTE + '/notify'}>connect</NavLink> your Telegram
                                    account to use real time notifications.
                                </Col>
                                :
                                null
                    }
                </Row>
                <Button
                    className="m-3 text-right"
                    variant="outline-primary"
                    onClick={addNotification}
                    disabled={!user.isAuth || !user.info.isActivated || !user.info.tgConnected}
                >Add</Button>

            </Form>
        </Card>
    );
});

export default SearchBarNotifications;