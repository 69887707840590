import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Button, Card, Col, Form, FormControl, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {LOGIN_ROUTE, USER_ROUTE} from "../utils/consts";
import InputGroup from "react-bootstrap/InputGroup";

const SearchBar = observer(() => {
    const {user, signal} = useContext(Context)

    return (
        <Row className="flex">
            <Card className="flex mt-3 mb-3 p-2">
                <Form className="flex">
                    <Row className="">
                        <Col sm={3}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="btnGroupVol">
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-volume">Current volume / Average last hour volume</Tooltip>}>
                                            <span style={{cursor: 'help', textDecoration: "underline", textDecorationStyle: "dashed"}}>Vol. rate</span>
                                        </OverlayTrigger>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    aria-describedby="btnGroupVol"
                                    placeholder="Volume rate"
                                    value={signal.params.volumeRate}
                                    onChange={e => signal.setParams(['volumeRate', e.target.value])}
                                    disabled={!user.isAuth || !user.info.isActivated}
                                    type={"number"}
                                />
                            </InputGroup>
                        </Col>
                        <Col sm={3}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="btnGroupPrice">
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-price">Price chg last minute / Price standard deviation last hour</Tooltip>}>
                                            <span style={{cursor: 'help', textDecoration: "underline", textDecorationStyle: "dashed"}}>Price dev.</span>
                                        </OverlayTrigger>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    aria-describedby="btnGroupPrice"
                                    placeholder="Price deviation rate"
                                    value={signal.params.deviationRate}
                                    onChange={e => signal.setParams(['deviationRate', e.target.value])}
                                    disabled={!user.isAuth || !user.info.isActivated}
                                    type={"number"}
                                />
                            </InputGroup>
                        </Col>
                        <Col sm={3}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="btnGroupDir">
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-dir">Price change direction</Tooltip>}>
                                            <span style={{cursor: 'help', textDecoration: "underline", textDecorationStyle: "dashed"}}>Direction</span>
                                        </OverlayTrigger>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <select
                                    className="form-control"
                                    id="priceChgDir"
                                    aria-describedby="btnGroupDir"
                                    value={signal.params.priceChgDir}
                                    onChange={e => signal.setParams(['priceChgDir', e.target.value])}
                                    disabled={!user.isAuth || !user.info.isActivated}
                                >
                                    <option>All</option>
                                    <option>Up</option>
                                    <option>Down</option>
                                </select>
                            </InputGroup>
                        </Col>
                        <Col sm={3}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="btnGroupFrame">
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-frame">Time frame</Tooltip>}>
                                            <span style={{cursor: 'help', textDecoration: "underline", textDecorationStyle: "dashed"}}>Time</span>
                                        </OverlayTrigger>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <select
                                    className="form-control"
                                    id="timeFrame"
                                    aria-describedby="btnGroupFrame"
                                    value={signal.params.timeFrame}
                                    onChange={e => signal.setParams(['timeFrame', e.target.value])}
                                    disabled={!user.isAuth || !user.info.isActivated}
                                >
                                    <option value={1}>1m</option>
                                    <option value={5}>5m</option>
                                    <option value={15}>15m</option>
                                    <option value={30}>30m</option>
                                    <option value={60}>1h</option>
                                    <option value={240}>4h</option>
                                </select>
                            </InputGroup>
                        </Col>
                        {
                            /*
                       <Col>
                           <span
                               style={{cursor: 'pointer'}}
                               className={'text-decoration-underline'}
                               data-toggle="tooltip"
                               data-placement="top"
                               title="Price chg last minute / Price standard deviation last hour"
                           >Price deviation</span>
                           <Form.Control

                               placeholder="Price deviation rate"
                               value={signal.params.deviationRate}
                               onChange={e => signal.setParams(['deviationRate', e.target.value])}
                               disabled={!user.isAuth || !user.info.isActivated}
                               type={"number"}
                           />
                       </Col>
                       */
                        }



                    </Row>
                    <Row className="d-flex font-weight-bold">
                        {!user.isAuth ?
                            <Col className="mt-3">
                                Please <NavLink to={LOGIN_ROUTE}>login</NavLink> to use filter.
                            </Col>
                            :
                            !user.info.isActivated ?
                                <Col className="mt-3">Please check email and activate your account to use
                                    filter.</Col>
                                :
                                /*
                                user.info.balance < user.info.prices.search ?
                                    <Col className="mt-3">
                                        Please <NavLink to={USER_ROUTE}>make subscription</NavLink> to get signals with no
                                        delay.
                                    </Col>
                                    :

                                 */
                                    <Col></Col>
                        }
                    </Row>
                </Form>
            </Card>
        </Row>
    );
});

export default SearchBar;