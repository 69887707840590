import {createChart, ColorType, CrosshairMode, LineStyle} from 'lightweight-charts';
import React, {useEffect, useRef, useContext} from 'react';
import {Context} from "../index";

export const ChartComponent = props => {
    //const {signal} = useContext(Context)
    const {
        data,
        colors: {
            backgroundColor = 'black',
            lineColor = '#2962FF',
            textColor = 'white',
            areaTopColor = '#2962FF',
            areaBottomColor = 'rgba(41, 98, 255, 0.28)',
        } = {},
    } = props;
    if (!data || !data.length) {
        return (<div></div>)
    }
    const chartContainerRef = useRef();

    useEffect(() => {
            const handleResize = () => {
                chart.applyOptions({width: chartContainerRef.current.clientWidth});
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: {type: ColorType.Solid, color: backgroundColor},
                    textColor,
                },
                grid: {
                    vertLines: {
                        color: "#222",
                    },
                    horzLines: {
                        color: "#222",
                    },
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                },
                priceScale: {
                    borderColor: "#485c7b",
                },
                timeScale: {
                    borderColor: "#485c7b",
                    timeVisible: true,
                    secondsVisible: false,
                },

                localization: {
                    //timeFormatter: (timestamp) => moment(timestamp).format('YYYY-MM-DD HH:mm'),
                    //timeVisible: true
                },

                width: chartContainerRef.current.clientWidth,
                height: 300,
            });
            chart.timeScale().fitContent();

            const candlestickSeries = chart.addCandlestickSeries({
                upColor: '#26a69a', downColor: '#ef5350', borderVisible: false,
                wickUpColor: '#26a69a', wickDownColor: '#ef5350',
            });
            candlestickSeries.priceScale().applyOptions({
                scaleMargins: {
                    // positioning the price scale for the area series
                    top: 0,
                    bottom: 0.1,
                },
            });
            candlestickSeries.setData(data);

            const volumeSeries = chart.addHistogramSeries({
                color: '#26a69a',
                priceFormat: {
                    type: 'volume',
                },
                priceScaleId: '', // set as an overlay by setting a blank priceScaleId
                // set the positioning of the volume series
                //scaleMargins: {
                //    top: 0.8, // highest point of the series will be 70% away from the top
                //    bottom: 0,
                //},
            });
            volumeSeries.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.8, // highest point of the series will be 70% away from the top
                    bottom: 0,
                },
            });
            volumeSeries.setData(data.map((v) => ({
                time: v.time,
                value: v.volume,
                color: v.open < v.close ? '#26a69a' : '#ef5350'
            })))
            const lineSeries = chart.addLineSeries({
                color: 'orange',
                lineWidth: 1
            })
            lineSeries.priceScale().applyOptions({
                scaleMargins: {
                    // positioning the price scale for the area series
                    top: 0,
                    bottom: 0.1,
                },
            });
            lineSeries.setData(data.map((v) => ({
                time: v.time,
                value: v.indexVal,
            })))

            let markers = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].marker) {
                    markers.push({
                        time: data[i].time,
                        position: 'aboveBar',
                        //color: '#e91e63',
                        color: '#f68410',
                        shape: 'arrowDown',
                        //text: ''
                        text: data[i].deviationRate.toFixed(1).toString(),
                    });
                }
            }

            candlestickSeries.setMarkers([]);
            candlestickSeries.setMarkers(markers);
            //console.log('markers',candlestickSeries.markers(),markers)
            window.addEventListener('resize', handleResize);
            //const dataUrl = chart.takeScreenshot().toDataURL()
            //console.log(dataUrl)
            return () => {
                window.removeEventListener('resize', handleResize);
                chart.remove();
            };
        },
        [data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
    );

    return (<div ref={chartContainerRef}/>);
};

export default ChartComponent;
