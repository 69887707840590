import {$authHost, $host, $binanceFAPI, $bybitAPI} from "./index";


export const fetchSignals = async (params) => {
    const {data} = await $host.get('/api/signals', {params})
    return data
}

export const fetchIndex = async (params) => {
    const {data} = await $host.get('/api/signals/index', {params})
    return data
}

export const fetchTradeStat = async (params) => {
    const {data} = await $host.get('/api/signals/tradeStat', {params})
    return data
}

export const fetchCurrPositions = async (params) => {
    const {data} = await $host.get('/api/signals/currPositions', {params})
    return data
}

export const fetchClosedPositions = async (params) => {
    const {data} = await $host.get('/api/signals/closedPositions', {params})
    return data
}

export const fetchSymbols = async (params) => {
    const {data} = await $host.get('/api/signals/symbols', {params})
    return data
}

export const fetchSignalsByVolume = async (params) => {
    const {data} = await $host.get('/api/signals/volumeMoreThanSMA', {params})
    return data
}

function timeToLocal(originalTime) {
    const d = new Date(originalTime);
    return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000;
}

export const fetchBinanceChartData = async (params) => {
    if (!params.symbol) return []
    const {data} = await $binanceFAPI.get('/fapi/v1/klines', {params})

    /*
    time: '2018-12-27', open: 91.04, high: 121.40, low: 82.70, close: 111.40
     1499040000000,      // Open time
                "0.01634790",       // Open
                "0.80000000",       // High
                "0.01575800",       // Low
                "0.01577100",       // Close
                "148976.11427815",  // Volume
                1499644799999,      // Close time
                "2434.19055334",    // Quote asset volume
                308,                // Number of trades
                "1756.87402397",    // Taker buy base asset volume
                "28.46694368",      // Taker buy quote asset volume
                "17928899.62484339" // Ignore.
     */


    return data.map(([time, open, high, low, close, volume, closeTime]) => ({
        time: timeToLocal(time),
        //new Date(time).toISOString().slice(0, 19).replace('T', ' '),
        //Date.UTC(time.getFullYear(), time.getMonth(), time.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds()) / 1000,
        //time/1000,
        open: parseFloat(open),
        high: parseFloat(high),
        low: parseFloat(low),
        close: parseFloat(close),
        volume: parseFloat(volume),
        closeTime
    }));
}

export const fetchBybitChartData = async (params) => {
    if (!params.symbol) return []
    const {data} = await $bybitAPI.get('/derivatives/v3/public/kline', {params})
    return data.result.list.reverse().map(([time, open, high, low, close, volume]) => ({
        time: timeToLocal(parseInt(time)),
        //new Date(time).toISOString().slice(0, 19).replace('T', ' '),
        //Date.UTC(time.getFullYear(), time.getMonth(), time.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds()) / 1000,
        //time/1000,
        closeTime: parseInt(time) + 1000 * 60,
        open: parseFloat(open),
        high: parseFloat(high),
        low: parseFloat(low),
        close: parseFloat(close),
        volume: parseFloat(volume),

    }));

}

