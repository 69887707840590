import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Card, Row} from "react-bootstrap";
import {SIGNALS_BY_VOLUME_ROUTE, SIGNALS_ROUTE, SYMBOL_ROUTE} from "../utils/consts";
import {useHistory} from "react-router-dom";

const SymbolBar = observer(() => {
    const {signal, user} = useContext(Context)
    const history = useHistory()
    //console.log(elephant.servers)

    return (
        <Row className="flex mt-3">
            <div className="p-2 font-weight-bold">Symbols:</div>
            <Card
                key={'all'}
                className="p-2"
                style={{cursor: 'pointer',}}
                onClick={() => {
                    history.push(SIGNALS_BY_VOLUME_ROUTE)
                    //signal.setParams(['symbol', s.symbol])
                    signal.setSelectedSymbol('')
                }}
                border={!signal.selectedSymbol ? 'primary' : 'light'}
            >
                All
            </Card>
            {
                signal.symbols.length
                    ?

                        signal.symbols.map(s =>
                                <Card
                                    key={s.symbol}
                                    className="p-2"
                                    style={{cursor: 'pointer',}}
                                    onClick={() => {
                                        signal.setParams(['symbol', s.symbol])
                                        signal.setSelectedSymbol(s.symbol)
                                        history.push(SYMBOL_ROUTE + '/' + s.symbol)
                                    }
                                    }
                                    border={s.symbol === signal.selectedSymbol ? 'primary' : 'light'}
                                >

                                    {s.symbol.slice(-4) == 'USDT' ?
                                        <span>
                                            <span>{s.symbol.replace('USDT', '')}</span>
                                            <span className={'text-secondary'}><small>usdt</small></span>
                                        </span>

                                        : s.symbol}
                                </Card>
                        )

                    :
                    null
            }
        </Row>
    );
});

export default SymbolBar;