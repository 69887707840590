//export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const RESETPASS_ROUTE = '/reset-pass'
export const NEWPASS_ROUTE = '/new-pass'
export const USER_ROUTE = '/me'

export const HOME_ROUTE = '/'
export const FAQ_ROUTE = '/faq'

//export const TWEET_ROUTE = '/tweet'
//export const TWEETS_ROUTE = '/tweets'

//export const ENTITY_ROUTE = '/entity'
//export const ENTITIES_ROUTE = '/entities'

//export const PERSONS_ROUTE = '/persons'
//export const PERSON_ROUTE = '/person'

export const STAT_ROUTE = '/statistics'
export const TRADE_STAT_ROUTE = '/trade-stat'

export const SIGNALS_ROUTE = '/signals'
export const SIGNALS_BY_VOLUME_ROUTE = '/signals-volume'

export const SYMBOL_ROUTE = '/symbol'
export const NOTIFICATIONS_ROUTE = '/notifications'


export const personEmotion = (i) => {
    if(i > 0.6)       return  (<span>&#x1F604;</span>)
    else if(i > 0.2)  return  (<span>&#x1F642;</span>)
    else if(i > -0.2) return  (<span>&#x1F610;</span>)
    else if(i > -0.6) return  (<span>&#x1F641;</span>)
    else              return  (<span>&#x1F621;</span>)
}
export const formatter = (num) => {
    let res = ''
    if(Math.abs(num)    > 999999) res = Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'M'
    else if(Math.abs(num)    > 9999) res = Math.sign(num)*((Math.abs(num)/1000).toFixed(0)) + 'k'
    else if(Math.abs(num) > 999) res = Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k'
    else res = Math.sign(num)*Math.abs(num)
    return res
}



