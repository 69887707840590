import React, {useContext, useEffect} from 'react';
import '../assets/new-age.css';
import '../assets/simple-line-icons/css/simple-line-icons.css'
import {LOGIN_ROUTE, SIGNALS_BY_VOLUME_ROUTE, SIGNALS_ROUTE, USER_ROUTE} from "../utils/consts";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {useHistory} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Footer from "../components/Footer";
import {Container, Row, Col, Card} from "react-bootstrap";
import {fetchNews} from "../http/newsAPI";
import signalsListShort from "../components/SignalsListShort";
import SignalsListShort from "../components/SignalsListShort";
import {fetchSignals} from "../http/signalAPI";
//import ReactGA from "react-ga";

const Home = observer(() => {
    const {user,news, signal} = useContext(Context)
    const history = useHistory()
    useEffect(() => {
        //fetchNews('All').then(data => news.setNews(data))
        //ReactGA.pageview(window.location.pathname + window.location.search);
        //console.log(news)
        signal.params.symbol = ''
        signal.params.volumeRate = 2.5
        signal.params.limit = 5
        fetchSignals(signal.params).then(data => {
            signal.setSignals(data)
        })
    },[])
    document.title = 'Crypto signals, pump finder';
    return (
        <div>
            <section className=" download">
                <div className="container h-100">
                    <div className="col-lg-8 my-auto mx-auto text-center">
                        <div className="header-content ">
                            <h1 className="mb-5">Crypto signals, pump finder</h1>
                            <h3>Trade better than whales!</h3>
                            <Button onClick={() => history.push(
                                user.isAuth ?
                                    user.info.balance > 10 ?
                                        SIGNALS_BY_VOLUME_ROUTE
                                        : USER_ROUTE
                                    : LOGIN_ROUTE
                            )}  className="btn btn-outline btn-xl js-scroll-trigger mt-5">Start Now!</Button>
                        </div>
                    </div>
                </div>
            </section>
            {/*
            <section className="container" id="download">
                    <Row className="row">
                        <Col className="text-center ">
                            <h3 className="section-heading"><a href={"https://crypto2pump.com/news/how-to-start/"}>How to start?</a></h3>
                        </Col>
                        <Col className="text-center ">
                            <h3 className="section-heading"><a href={"https://crypto2pump.com/news/finder/"}>How to find signals?</a></h3>
                        </Col>
                        <Col>
                            <h3 className="section-heading"><a href={"https://crypto2pump.com/news/features/"}>Features</a></h3>
                        </Col>
                    </Row>
            </section>
            */
            }

            {news && news.news && news.news.forum && news.news.forum.length
                ? <section className="container" id="download">
                    <h3 className="section-heading">Last forum topics</h3>
                    <Row>
                        {news.news.forum.map( (n,k) =>
                            <Card
                                key={k}
                                className="p-2 d-inline m-1"
                                //style={{cursor: 'pointer',}}
                                //onClick={()=> servers.setSelectedZone(zone)}
                                //border={zone.zone === servers.selectedZone.zone ? 'primary':'light'}
                            >

                                <a href={n.url} target={"_blank"} className="font-weight-bold">
                                    {n.title}
                                </a><br/><span className="text-secondary" style={{fontSize: 'small'}}>{n.updated}</span>
                            </Card>
                        )}
                    </Row>
                </section>
                : null
            }
            <section className="download">
                <div className="container col-lg-8 my-auto mx-auto text-center">
                    <h3>Latest signals</h3>
                    <SignalsListShort/>
                    <Button onClick={() => history.push(
                        user.isAuth ?
                            user.info.balance > 10 ?
                                SIGNALS_BY_VOLUME_ROUTE
                                : USER_ROUTE
                            : LOGIN_ROUTE
                    )}  className="btn btn-outline btn-xl js-scroll-trigger mt-5">View more signals</Button>
                </div>
            </section>

            <section className="features" id="features">
                <div className="container">
                    <div className="section-heading text-center">
                        <h3>Crypto signals: <a href={"https://crypto2pump.com/news/features/"}>New Features</a>, Unlimited Profit</h3>
                        <p className="text-muted"><a href={"https://crypto2pump.com/news/features/"}>Check out what you can do with Crypto Pump!</a></p>
                        <hr/>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 my-auto mx-auto">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="feature-item">
                                            <i className="icon-bell text-primary"></i>
                                            <h3>Notifications</h3>
                                            <p className="text-muted">Get notified about new signals in Telegram and Discord</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="feature-item">
                                            <i className="icon-social-twitter text-primary"></i>
                                            <h3>Twitter analytics</h3>
                                            <p className="text-muted">Check the popularity of each cryptocurrency on Twitter in real time.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="feature-item">
                                            <i className="icon-present text-primary"></i>
                                            <h3>Loyal price</h3>
                                            <p className="text-muted">We are testing new features. The cost of using signals is very low.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="feature-item">
                                            <i className="icon-refresh text-primary"></i>
                                            <h3>Crypto signals</h3>
                                            <p className="text-muted">Signals are generated by smart algorithms based on real-time data from Binance and Bybit.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cta">
                <div className="cta-content">
                    <div className="container">
                        <h2>Stop waiting.<br/>Start trading.</h2>
                        <Button onClick={() => history.push(
                            user.isAuth ?
                                user.info.balance > 10 ?
                                    SIGNALS_ROUTE
                                    : USER_ROUTE
                                : LOGIN_ROUTE
                        )} style={{cursor:'pointer'}} className="btn btn-outline btn-xl js-scroll-trigger">Let's Get Started!</Button>
                    </div>
                </div>
                <div className="overlay"></div>
            </section>
            <section className="contact bg-light" id="contact">
                <div className="container">
                    <h2>We <i className="icon-heart text-primary"></i> new friends!</h2>
                    <h3>
                        <b>Join Crypto Pump: <a href="https://discord.gg/DVz9CKjAw3">Discord</a>, <a href="https://t.me/crypto_554">Telegram</a>
                        </b>
                    </h3>
                </div>
            </section>
            <footer>
                <Footer/>
            </footer>
        </div>
    );
});

export default Home;