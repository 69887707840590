import React, {useContext, useEffect, useState} from 'react';
import {Button, Container, Form, Tabs, Tab} from "react-bootstrap";
import Card from 'react-bootstrap/Card'
import {Context} from "../index";
import {checkout, price, tgToken} from "../http/userAPI";
import Footer from "../components/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactGA from "react-ga4";
import {observer} from "mobx-react-lite";
import {USER_ROUTE, NOTIFICATIONS_ROUTE, SIGNALS_BY_VOLUME_ROUTE, SYMBOL_ROUTE} from "../utils/consts";
import {useHistory, useLocation} from 'react-router-dom'
import {values} from "mobx";
//import {}

//const User = () => {
const UserPage = observer(() => {
        const history = useHistory()
        const [product, setProduct] = useState('Standard-3m')
        const [tgTokenStr, setTgTokenStr] = useState('')
        const [key, setKey] = useState('manage');

        const location = useLocation();

        const changeProduct = (event) => {
            let {name, checked, value} = event.target;
            //value = parseInt(value)
            console.log(name, checked, value)
            if (checked) {
                setProduct(value)
                //console.log(value,product)
            }
        }

        const getTgToken = async () => {
            setTgTokenStr((await tgToken()).tgToken)
        }
        const {user} = useContext(Context)

        const clickCheckout = async () => {
            try {
                let data
                ReactGA.event({category: 'User', action: 'Checkout'});
                data = await checkout(product)
                if (data.url) window.location.assign(data.url);
            } catch (e) {
                alert(e.response.data.message)
            }
        }
        const Products = [
            //{id: 1, price: 1.99,  points:   60,  pointsOld:  30, name: 'Lite',item_number: 'item-1'},
            {id: 1, price: 9.99, points: 90, pointsOld: 45, name: '1 month', item_number: 'item-1'},
            {id: 2, price: 19.99, points: 200, pointsOld: 100, name: '3 months', item_number: 'item-2', bestOffer: true},
            {id: 3, price: 49.99, points: 500, pointsOld: 250, name: '12 months', item_number: 'item-3'},
        ]
        //ReactGA.event({category: 'User', action: 'Open profile'});
        //ReactGA.pageview(window.location.pathname + window.location.search);
        document.title = 'Crypto signals, pump finder';

        useEffect(() => {
            price().then(data => {
                user.setPrice(data)
                console.log(user.price)
            })
            let wlp = location.pathname
            if (wlp.includes('/notify') && key != 'notifications') {
                setKey('notifications')
            }
        }, [])
//console.log(user)
        return (
            <Container className="d-flex flex-column ">
                <div>
                    <Tabs
                        activeKey={key}
                        onSelect={(k) => {
                            console.log(k)
                            setKey(k)
                            if (k == 'notifications') {
                                history.push(USER_ROUTE + '/notify')
                            } else {
                                history.push(USER_ROUTE)
                            }
                        }}
                        defaultActiveKey="manage"
                        transition={false}
                        id="noanim-tab"
                        className="mb-3 mt-4"
                        variant="pills"
                    >
                        <Tab eventKey="manage" title="Manage">
                            <Card className="mt-3 p-3">
                                <div className="mt-3 font-weight-bold">My account</div>
                                <div className="mt-3">Email: {user.info.email}</div>
                                {new Date(user.info.expDate) > new Date() ?
                                    <div>
                                        <div>Subscription: Standard</div>
                                        <div>Expiration date: {new Date(user.info.expDate).toDateString()}</div>
                                        <div>Days
                                            left: {Math.round((new Date(user.info.expDate) - new Date()) / (24 * 60 * 60 * 1000))}</div>
                                    </div>
                                    :
                                    <div>
                                        <div>Subscription: None</div>
                                    </div>

                                }

                                {
                                    /*
                                    <div className="mt-3">Create new bot cost: <i className={"gold"}></i>{user.info.prices && user.info.prices.createBot ?user.info.prices.createBot : null} points</div>
                                <div className="">Search elephants, croppers or oases cost: <i className={"gold"}></i>{user.info.prices && user.info.prices.search ? user.info.prices.search : null} points</div>

                                     */
                                }
                            </Card>
                            <Card className="mt-3 p-3 text-success">
                                <div className="mt-3 font-weight-bold">Discount!</div>
                                <div className="mt-3 font-weight-bold">
                                    Buy today to get discount!
                                </div>
                            </Card>
                            <Card className="mt-3 p-3">
                                <div className="mt-3 p-2">
                                    <div className="font-weight-bold mb-2"></div>
                                    <Row style={{maxWidth: '500px', borderBottom: '1px solid rgba(0, 0, 0, 0.1)',}}>
                                        <Col className="font-weight-bold">Type</Col>

                                        <Col className="font-weight-bold text-right">1&nbsp;month</Col>
                                        <Col className="font-weight-bold text-right">3&nbsp;months</Col>
                                        <Col className="font-weight-bold text-right">6&nbsp;months</Col>
                                    </Row>
                                    {user.price.map((prod, i) => (
                                        <Row key={i}
                                             style={{maxWidth: '500px', borderBottom: '1px solid rgba(0, 0, 0, 0.1)'}}>
                                            <Col className="">{prod.type_name}</Col>
                                            <Col className="text-right">
                                                <del className="text-secondary">${prod.price_old_1m}</del>
                                                <br/><span className="text-success font-weight-bold">${prod.price_1m}</span>
                                            </Col>
                                            <Col className="text-right">
                                                <del className="text-secondary">${prod.price_old_3m}</del>
                                                <br/><span className="text-success font-weight-bold">${prod.price_3m}</span>
                                            </Col>
                                            <Col className="text-right">
                                                <del className="text-secondary">${prod.price_old_6m}</del>
                                                <br/><span className="text-success font-weight-bold">${prod.price_6m}</span>
                                            </Col>

                                        </Row>
                                    ))}
                                </div>
                                <Form className="mt-3 p-2">
                                    <div className="font-weight-bold mb-2">Buy subscription</div>
                                    <div className="mb-3 text-secondary">You will be redirected to <b>Stripe</b> payment
                                        system (Visa,
                                        Mastercard, Google Pay, Apple Pay). <br/> Subscription will be activated immediately
                                        after
                                        payment.
                                    </div>
                                    <div key={'product'}>
                                        {
                                            user.price.map(prod =>
                                                <div key={prod.type_name}>
                                                    <Form.Check
                                                        key={`${prod.type_name}-1m`}
                                                        type="radio"
                                                        name="group1"
                                                        id={`${prod.type_name}-1m`}
                                                        label={` ${prod.type_name} 1 month, $${prod.price_1m}`}
                                                        value={`${prod.type_name}-1m`}
                                                        checked={product === `${prod.type_name}-1m`}
                                                        onChange={changeProduct}
                                                    />
                                                    <Form.Check
                                                        key={`${prod.type_name}-3m`}
                                                        type="radio"
                                                        name="group1"
                                                        id={`${prod.type_name}-3m`}
                                                        label={` ${prod.type_name} 3 months, $${prod.price_3m}`}
                                                        value={`${prod.type_name}-3m`}
                                                        checked={product === `${prod.type_name}-3m`}
                                                        onChange={changeProduct}
                                                    />
                                                    <Form.Check
                                                        key={`${prod.type_name}-6m`}
                                                        type="radio"
                                                        name="group1"
                                                        id={`${prod.type_name}-6m`}
                                                        label={` ${prod.type_name} 6 months, $${prod.price_6m}`}
                                                        value={`${prod.type_name}-6m`}
                                                        checked={product === `${prod.type_name}-6m`}
                                                        onChange={changeProduct}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                    <Button
                                        //variant={'outline-primary'}
                                        className="mt-3"
                                        onClick={clickCheckout}
                                    >
                                        Buy
                                    </Button>
                                </Form>
                                {
                                    /*
                                <div className="font-weight-bold mt-4">Buy points (Paypal)</div>
                                <div className="mt-2">
                                    An alternative payment method - Paypal<br/>
                                    Pay to <strong>4845637@gmail.com</strong><br/>
                                    After payment, write to 4845637@gmail.com<br/>
                                    Points will be added within an hour
                                </div>

                                     */
                                }
                            </Card>
                            <Card className="mt-3 p-3 text-secondary">
                                <div className="mt-3 font-weight-bold">Why should I buy?</div>
                                <div className="mt-1">
                                    We scan the futures and spot markets for various signals for successful trades.<br/>
                                    We show the found signals with some delay (from 30 minutes to 3 hours)<br/>
                                    In order to receive signals without delay, you can subscribe for 1, 3 or 6 months. It's
                                    only
                                    $9.<br/>
                                    Happy trading!
                                </div>
                            </Card>
                        </Tab>
                        <Tab eventKey="notifications" title="Notifications">
                            <Card className="mt-3 p-3">
                                <div className="mt-3 font-weight-bold">Telegram</div>
                                {
                                    user.info.tgConnected ?
                                        <div>
                                            Telegram account <span className="text-success">connected</span>.<br/>
                                            You can set <a className={'text-primary'}
                                                       style={{cursor: 'pointer'}}
                                                       onClick={() => history.push(NOTIFICATIONS_ROUTE)}>notifications
                                            parameters
                                        </a>
                                        </div>
                                        :
                                        <div>
                                            Telegram account <span className="text-secondary">not connected</span>.
                                            <div className="mt-3">How to receive notifications in Telegram</div>
                                            <ul>
                                                <li>Open <a href={"https://t.me/CryptoPumpFinderBot"} target={"_blank"}>Bot
                                                    in Telegram</a></li>

                                                <li><a
                                                    onClick={() => {
                                                        getTgToken()
                                                        console.log(tgTokenStr)
                                                    }}
                                                    className={'text-primary'}
                                                    style={{cursor: 'pointer'}}
                                                >Generate code</a> and copy it to Bot: <b>{tgTokenStr}</b></li>
                                                <li>Set <a className={'text-primary'}
                                                           style={{cursor: 'pointer'}}
                                                           onClick={() => history.push(NOTIFICATIONS_ROUTE)}>notifications
                                                    parameters
                                                </a>
                                                </li>
                                            </ul>
                                        </div>
                                }
                            </Card>
                            <Card className="mt-3 p-3">
                                <div className="mt-3 font-weight-bold">Discord</div>
                                <div className="mt-3">Not ready yet. In progress...</div>
                            </Card>
                        </Tab>
                    </Tabs>
                </div>
                <Footer/>
            </Container>
        );
    })
;

export default UserPage;
