import {
    FAQ_ROUTE,
    HOME_ROUTE,
    LOGIN_ROUTE,
    REGISTRATION_ROUTE,
    RESETPASS_ROUTE,
    NEWPASS_ROUTE,
    USER_ROUTE,
    SIGNALS_ROUTE,
    SIGNALS_BY_VOLUME_ROUTE,
    SYMBOL_ROUTE,
    NOTIFICATIONS_ROUTE,
    TRADE_STAT_ROUTE
} from "./utils/consts";

import Auth from "./pages/Auth";
import User from "./pages/User";
import Home from "./pages/Home";
import Faq from "./pages/Faq";
import ResetPass from "./pages/ResetPass";
import NewPass from "./pages/NewPass";
import Signals from "./pages/Signals";
import SignalsByVolume from "./pages/SignalsVolume";
import Symbol from "./pages/Symbol";
import Notifications from "./pages/Notifications";
import TradeStat from "./pages/TradeStat";


export const authRoutes = [
    {
        path: USER_ROUTE,
        Component: User
    },

    {
        path: USER_ROUTE + '/notify',
        Component: User
    },
]

export const publicRoutes = [
    {
        path: HOME_ROUTE,
        Component: Home
    },

    {
        path: FAQ_ROUTE,
        Component: Faq
    },

    {
        path: LOGIN_ROUTE,
        Component: Auth
    },

    {
        path: REGISTRATION_ROUTE,
        Component: Auth
    },

    {
        path: RESETPASS_ROUTE,
        Component: ResetPass
    },

    {
        path: NEWPASS_ROUTE + '/:passToken',
        Component: NewPass
    },

    {
        path: NEWPASS_ROUTE,
        Component: NewPass
    },

    {
        //path: TWEETS_ROUTE + '/:type/:entity/:page',
        path: SYMBOL_ROUTE + '/:symbol',
        Component: Symbol
    },

    {   path: SIGNALS_ROUTE,
        Component: Signals
    },

    {   path: SIGNALS_BY_VOLUME_ROUTE,
        Component: SignalsByVolume
    },

    {   path: NOTIFICATIONS_ROUTE,
        Component: Notifications
    },

    {   path: TRADE_STAT_ROUTE,
        Component: TradeStat
    },
]