import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import '../assets/style.css';
import {Card, Col, Container, Row, Table} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {SYMBOL_ROUTE} from "../utils/consts";

const CurrPositionsList = observer(() => {
    const {signal} = useContext(Context)
    const history = useHistory()

    const timeAgoSignal = (time) => {
        let s = new Date(time)
        return (<span>{new Intl.DateTimeFormat('en-US',
            {month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: "h24"}
        ).format(s)}</span>)
    }

    if (signal.currPositions && Object.keys(signal.currPositions)) {
        //console.log('got signals', Object.keys(signal.signals))
        //console.log(signal.signals)
        //let signalsShort = signal.signals.slice(0,5)
        return (
            <Col className="flex mt-3">
                <h4>Current positions</h4>
                <Row>
                    <Col><b>Symbol</b></Col>
                    <Col><b>Time</b></Col>
                    <Col className={'text-right'}><b>Unr PNL</b></Col>
                </Row>
                {
                    signal.currPositions.map((s, index) =>
                        <Row key={s.symbol}>

                            <Col>
                                <small>{index +1}.</small>&nbsp;
                                <a
                                    onClick={() => {
                                        signal.setParams(['symbol', s.symbol])
                                        signal.setSelectedSymbol(s.symbol)
                                        history.push(SYMBOL_ROUTE + '/' + s.symbol)
                                    }}
                                    className={'text-primary'}
                                    style={{cursor: 'pointer'}}
                                >
                                    {s.symbol.slice(-4) == 'USDT'
                                        ?
                                            <span>
                                                <span>{s.symbol.replace('USDT', '')}</span> <span><small>usdt</small></span>
                                            </span>
                                        : s.symbol
                                    }
                                </a>
                            </Col>
                            <Col >
                                <small>{timeAgoSignal(s.updateTime)}</small>
                            </Col>
                            <Col className={'text-right'} style={{
                                color: s.unrealizedProfit < 0 ? '#F6465D' : '#03A66D',
                                }}>
                                {(s.unrealizedProfit * 1).toFixed(2)}
                            </Col>
                        </Row>
                    )
                }
            </Col>
        );
    } else {
        console.log(signal.signals)
        return (
            <Row className="d-flex mt-3 mb-5">
                No items
            </Row>
        );
    }
});

export default CurrPositionsList;