import {makeAutoObservable} from "mobx";

export default class SignalsStore {
    constructor() {
        this._signals = []
        this._symbols = []
        this._chartDataBinance = []
        this._chartDataBybit = []
        this._tradeStat = {}
        this._currPositions = []
        this._closedPositions = []
        this._params = {
            volumeRate: 1.5,
            deviationRate: 1.2,
            symbol: '',
            period: 1, //days
            limit: 30,
            page: 1,
            priceChgDir: 'All',
            timeFrame: 1
        }
        this._selectedSymbol = ''
        this._selectedSignal = {}
        this._page = 1
        this._totalCount = 0
        this._limit = 20
        makeAutoObservable(this)
    }
    setSignals(signals){
        this._signals = signals
    }
    setParams(params){
        this.setPage(1)
        this._params[params[0]] = params[1]
    }
    setSymbols(symbols){
        this._symbols = symbols
    }
    setPage(page) {
        this._page = page
    }
    setTotalCount(count) {
        this._totalCount = count
    }
    setSelectedSymbol(symbol) {
        this._selectedSymbol = symbol
    }
    setSelectedSignal(signal) {
        this._selectedSignal = signal
    }
    setChartDataBybit(data) {
        this._chartDataBybit = data
    }
    get chartDataBybit(){
        return this._chartDataBybit
    }

    setChartDataBinance(data) {
        this._chartDataBinance = data
    }
    get chartDataBinance(){
        return this._chartDataBinance
    }

    setTradeStat(data) {
        this._tradeStat = data
    }
    get tradeStat(){
        return this._tradeStat
    }
    setCurrPositions(data) {
        this._currPositions = data
    }
    get currPositions(){
        return this._currPositions
    }

    setClosedPositions(data) {
        this._closedPositions = data
    }
    get closedPositions(){
        return this._closedPositions
    }


    get selectedSymbol(){
        return this._selectedSymbol
    }
    get params(){
        return this._params
    }
    get signals(){
        return this._signals
    }
    get selectedSignal(){
        return this._selectedSignal
    }
    get symbols(){
        return this._symbols
    }
    get totalCount() {
        return this._totalCount
    }
    get page() {
        return this._page
    }
    get limit() {
        return this._limit
    }
}