import axios from 'axios'

const $host = axios.create({
    //withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL
})

const $authHost = axios.create({
    //withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL
})

const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}
$authHost.interceptors.request.use(authInterceptor)


const $binanceFAPI = axios.create({
    //withCredentials: true,
    baseURL: 'https://fapi.binance.com'
})

const $bybitAPI = axios.create({
    //withCredentials: true,
    baseURL: 'https://api.bybit.com'
})

export {
    $host,
    $authHost,
    $binanceFAPI,
    $bybitAPI
}