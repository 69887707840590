import {createChart, ColorType, CrosshairMode, LineStyle} from 'lightweight-charts';
import React, {useEffect, useRef, useContext} from 'react';
import {Context} from "../index";

export const ChartTradeStatComponent = props => {

    const {
        data,
        colors: {
            backgroundColor = 'black',
            lineColor = '#2962FF',
            textColor = 'white',
            areaTopColor = '#2962FF',
            areaBottomColor = 'rgba(41, 98, 255, 0.28)',
        } = {},
    } = props;
    if (!data || !data.totalMarginBalance) {
        //console.log(data)
        return (<div></div>)
    }
    const chartContainerRef = useRef();

    useEffect(
        () => {
            const handleResize = () => {
                chart.applyOptions({width: chartContainerRef.current.clientWidth});
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: {type: ColorType.Solid, color: backgroundColor},
                    textColor,
                },
                grid: {
                    vertLines: {
                        color: "#222",
                    },
                    horzLines: {
                        color: "#222",
                    },
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                },
                priceScale: {
                    borderColor: "#485c7b",
                },
                timeScale: {
                    borderColor: "#485c7b",
                    timeVisible: true,
                    secondsVisible: false,
                },

                localization: {
                    //timeFormatter: (timestamp) => moment(timestamp).format('YYYY-MM-DD HH:mm'),
                    //timeVisible: true
                },

                width: chartContainerRef.current.clientWidth,
                height: 300,
            });
            chart.timeScale().fitContent();

            /*
                lineSeries.priceScale().applyOptions({
                scaleMargins: {
                    top: 0,
                    bottom: 0.1,
                },
            });
             */
            const lineSeries1 = chart.addLineSeries({color: 'orange',lineWidth: 3})
            lineSeries1.setData(data.totalMarginBalance)

            const lineSeries2 = chart.addLineSeries({color: 'red',lineWidth: 3})
            lineSeries2.setData(data.totalUnrealizedProfit)

            const lineSeries3 = chart.addLineSeries({color: 'blue',lineWidth: 3})
            lineSeries3.setData(data.totalWalletBalance)


            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
                chart.remove();
            };
        },
        [data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
    );

    return (<div ref={chartContainerRef}/>);
};

export default ChartTradeStatComponent;
