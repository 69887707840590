import React, {useContext, useEffect, useState} from 'react';
import {Container, OverlayTrigger, Tooltip} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import {fetchClosedPositions, fetchCurrPositions, fetchTradeStat} from "../http/signalAPI";
import {ChartTradeStatComponent} from "../components/ChartTradeStat";
import InputGroup from "react-bootstrap/InputGroup";
import CurrPositionsList from "../components/CurrPositionsList";
import ClosedPositionsList from "../components/ClosedPositionsList";

const TradeStatPage = observer(() => {
    const {signal, user} = useContext(Context)
    //const {symbol} = useParams()
    let closedPos = []

    useEffect(() => {
        fetchTradeStat(signal.params).then(data => {signal.setTradeStat(data)})
        fetchCurrPositions().then(data => {signal.setCurrPositions(data)})
        fetchClosedPositions().then(data => {signal.setClosedPositions(data)})
    }, [])
/*
    useEffect(() => {
        fetchTradeStat(signal.params).then(data => {signal.setTradeStat(data)})
    }, [signal.params.timeFrame])
*/
    let ogTitle = 'Trade stat'
    let h1 = 'Trade stat'
    let ogDescription = ''
    let ogImage = 'og_image1.png'

    const props = {}
    return (
        <Container>
            <Helmet>
                <title>{ogTitle}</title>
                <meta name="description" content={ogDescription}/>
                <meta name="twitter:card" content={ogImage}/>
                <meta name="twitter:title" content={ogTitle}/>
                <meta name="twitter:description" content={ogDescription}/>
                <meta name="twitter:image" content={ogImage}/>
                <meta property="og:title" content={ogTitle}/>
                <meta property="og:description" content={ogDescription}/>
                <meta property="og:image" content={ogImage}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:site_name" content="Crypto Analytics"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
            </Helmet>
            <Row className="mt-2">
                <Col>
                    <h1 style={{fontSize: '18px'}}>{h1}</h1>
                    {
                        /*
                    <InputGroup className="mb-2 mt-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="btnGroupFrame">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-frame">Time frame</Tooltip>}>
                                    <span style={{cursor: 'help', textDecoration: "underline", textDecorationStyle: "dashed"}}>Time</span>
                                </OverlayTrigger>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <select
                            className="form-control"
                            id="timeFrame"
                            aria-describedby="btnGroupFrame"
                            value={signal.params.timeFrame}
                            onChange={e => signal.setParams(['timeFrame', e.target.value])}
                            //disabled={!user.isAuth || !user.info.isActivated}
                        >
                            <option value={5}>5m</option>
                            <option value={15}>15m</option>
                            <option value={30}>30m</option>
                            <option value={60}>1h</option>
                            <option value={240}>4h</option>
                        </select>
                    </InputGroup>
                         */
                    }
                    <ChartTradeStatComponent {...props} data={signal.tradeStat}></ChartTradeStatComponent>
                    <ClosedPositionsList/>
                    <CurrPositionsList/>
                    <Footer/>
                </Col>
            </Row>
        </Container>
    );
});

export default TradeStatPage;