import React from 'react';
import {observer} from "mobx-react-lite";
import  {Container,Row,Col} from "react-bootstrap";
import {TRADE_STAT_ROUTE, TWEET_ROUTE, USER_ROUTE} from "../utils/consts";
import {useHistory} from 'react-router-dom'

const Footer = observer(() => {
    const history = useHistory()
    return (
            <Container className="mt-5 mb-1">
                <Row className="mb-1">
                    <Col className="justify-content-center">
                        <Row className="justify-content-center"><a target="_blank" rel="noreferrer" href="https://crypto2pump.com/news/faq/">FAQ</a></Row>
                        <Row className="justify-content-center"><a target="_blank" rel="noreferrer" href="https://crypto2pump.com/news/">News</a></Row>
                    </Col>
                    <Col className="justify-content-center">
                        <Row className="justify-content-center"><a className={'text-primary'} onClick={()=> history.push(USER_ROUTE)} style={{cursor:'pointer'}}>Buy subscription</a></Row>
                        <Row className="justify-content-center"><a className={'text-primary'} onClick={()=> history.push(TRADE_STAT_ROUTE)} style={{cursor:'pointer'}}>Trade statistic</a></Row>

                    </Col>
                    <Col>
                        <Row className="justify-content-center"><a target="_blank" rel="noreferrer" href="https://discord.gg/DVz9CKjAw3">Discord</a></Row>
                        <Row className="justify-content-center"><a target="_blank" rel="noreferrer" href="https://t.me/crypto_554">Telegram</a></Row>
                    </Col>
                </Row>
                <Row className=" mt-3 justify-content-center">Find pump, dump and other crypto signals on whole market</Row>
                <Row className="justify-content-center"><a href="mailto:sales@crypto2pump.com">sales@crypto2pump.com</a></Row>
                <Row className="justify-content-center">2022-2024</Row>

            </Container>
    );
});

export default Footer;