import React, {useContext, useEffect, useState} from 'react';
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import SearchBar from "../components/SearchBar";
//import ReactGA from "react-ga";
import Footer from "../components/Footer";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {fetchBinanceChartData, fetchBybitChartData, fetchIndex, fetchSignals, fetchSymbols} from "../http/signalAPI";
//import SignalsList from "../components/SignalsList";
import SignalsListByVolume from "../components/SignalsListByVolume";
import SymbolBar from "../components/SymbolBar";
import {ChartComponent} from "../components/Chart";

const SymbolPage = observer(() => {
    const {signal, user} = useContext(Context)
    const {symbol} = useParams()
    //console.log('symbol', symbol)
    //signal.setParams(['symbol', symbol])
    //signal.setSelectedSymbol(symbol)
    //console.log('symbol 2', signal.selectedSymbol)
    function prepareExchangeData() {
        let limit = 99
        let symbol = signal.params.symbol
        let lastSignalMinAgo = (new Date().getTime() - new Date(signal.selectedSignal.timeClose).getTime()) / 60000
        let timeFrame = signal.params.timeFrame
        let binanceInterval = '1m'
        //console.log(timeFrame,[1,5,15,30].includes(parseInt(timeFrame)))
        if ([1, 5, 15, 30].includes(parseInt(timeFrame))) {
            binanceInterval = timeFrame.toString() + 'm'
        } else if (timeFrame == 60) {
            binanceInterval = '1h'
        } else if (timeFrame == 240) {
            binanceInterval = '4h'
        }
        let paramsBinance = {symbol, interval: binanceInterval, limit}
        let paramsBybit = {
            symbol,
            interval: '1',
            end: new Date().getTime(),
            start: new Date().getTime() - limit * 60 * 1000
        }
        if (lastSignalMinAgo > 95) {
            let start = new Date(signal.selectedSignal.timeClose).getTime() - 10 * 60 * 1000 * signal.params.timeFrame

            paramsBinance = {
                symbol, interval: binanceInterval, limit,
                startTime: start
            }
            paramsBybit = {
                symbol,
                interval: '1',
                end: start + limit * 60 * 1000,
                start
            }
        }
        //console.log(paramsBinance)
        //let index = fetchIndex()
        //console.log(index)
        fetchBinanceChartData(paramsBinance).then(data => {

            //let avgPrice = signal.signals[0].close
            let avgPrice = data[data.length - 1].close

            fetchIndex().then(index => {
                data = data.map((v) => {
                    let marker = false
                    let deviationRate = 0
                    for (let i = 0; i < signal.signals.length; i++) {
                        //console.log(Math.abs(new Date(signal.signals[i].timeClose).getTime() - v.closeTime) / 1000)
                        if (Math.abs(new Date(signal.signals[i].timeClose).getTime() - v.closeTime) / 1000 < 10) {
                            marker = true
                            deviationRate = signal.signals[i].deviationRate
                            break
                        }
                    }
                    let indexVal = 0
                    //console.log('ind len', index)
                    for (let i = 0; i < index.length; i++) {
                        //console.log(Math.abs(new Date(index[i].timeClose).getTime() - v.closeTime) / 1000)
                        if (Math.abs(new Date(index[i].timeClose).getTime() - v.closeTime) / 1000 < 10) {
                            indexVal = index[i].ind1 * avgPrice
                            //console.log('index', indexVal, index[i].ind1, avgPrice)
                            break
                        }
                    }
                    if (indexVal > 0) {
                        return {...v, marker, deviationRate, indexVal}
                    }
                    return {...v, marker, deviationRate}
                })
                //console.log(data)
                signal.setChartDataBinance(data)
            })

        })
        /*
                fetchBybitChartData(paramsBybit).then(data => {
                    data = data.map((v) => {
                        let marker = false
                        let deviationRate = 0
                        for (let i = 0; i < signal.signals.length; i++) {
                            if (Math.abs(new Date(signal.signals[i].timeClose).getTime() - v.closeTime) / 1000 < 10) {
                                //console.log('got marker', new Date(signal.signals[i].timeClose))
                                marker = true
                                deviationRate = signal.signals[i].deviationRate
                                break
                            }
                        }
                        return {...v, marker, deviationRate}
                    })
                    signal.setChartDataBybit(data)
                    //console.log('bybit', data)
                })

         */


    }

    useEffect(() => {
        //fetchSymbols(signal.params).then(data => {signal.setSymbols(data)})
        if (signal.selectedSymbol != symbol) {
            signal.setSelectedSymbol(symbol)
            signal.setParams(['symbol', symbol])
            fetchSignals(signal.params).then(data => {
                signal.setSignals(data)
            })
        }
    }, [])

    useEffect(() => {
        fetchSignals(signal.params).then(data => {
            signal.setSignals(data)
            signal.setSelectedSignal(signal.signals[0])
            prepareExchangeData()

        })
        fetchSymbols(signal.params).then(data => {
            signal.setSymbols(data)
        })
    }, [signal.params.volumeRate, signal.params.deviationRate, signal.params.priceChgDir, signal.params.timeFrame, signal.selectedSymbol])

    useEffect(() => {
        prepareExchangeData()
    }, [signal.selectedSignal])

    /*
    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchSignals(signal.params).then(data => {
                signal.setSignals(data)
            })
        }, 60000)
        return () => clearInterval(intervalId);
    }, [])


   */
    useEffect(() => {

    }, [signal.signals])


    //console.log(signal.symbols)

    let ogTitle = 'Signals list ' + symbol
    let h1 = 'Signals list ' + symbol
    let ogDescription = ''
    let ogImage = 'og_image1.png'

    const props = {}
    return (
        <Container>
            <Helmet>
                <title>{ogTitle}</title>
                <meta name="description" content={ogDescription}/>
                <meta name="twitter:card" content={ogImage}/>
                <meta name="twitter:title" content={ogTitle}/>
                <meta name="twitter:description" content={ogDescription}/>
                <meta name="twitter:image" content={ogImage}/>
                <meta property="og:title" content={ogTitle}/>
                <meta property="og:description" content={ogDescription}/>
                <meta property="og:image" content={ogImage}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:site_name" content="Crypto Analytics"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
            </Helmet>

            <h1 style={{fontSize: '18px'}} className={"mt-2"}>{h1.slice(-4) == 'USDT' ?
                <span>
                    <span>{h1.replace('USDT', '')}</span>
                    <span className={'text-secondary'}><small>USDT</small></span>
                </span>
                :
                <span>
                    <span>{h1}</span>
                </span>
            }</h1>

            <SymbolBar/>
            <SearchBar/>
            <ChartComponent {...props} data={signal.chartDataBinance}></ChartComponent>

            {/*
                    <ChartComponent {...props} data={signal.chartDataBybit}></ChartComponent>

                       */
            }

            <SignalsListByVolume/>
            <Footer/>

        </Container>
    );
});


export default SymbolPage;