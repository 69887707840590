import React, {useContext, useEffect, useState} from 'react';
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
//import Pages from "../components/Pages";
//import ReactGA from "react-ga";
import Footer from "../components/Footer";
//import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {fetchSignals} from "../http/signalAPI";
//import SignalsList from "../components/SignalsList";
//import EntitiesBar from "../components/EntitiesBar";

const SignalsPage = observer(() => {
    const {user,signal} = useContext(Context)
/*
    useEffect(() => {
        fetchSignals('').then(data => {
            signal.setSignals(data)
        })
    },[])
*/
    useEffect(() => {
        const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
            fetchSignals('').then(data => {
                signal.setSignals(data)
            })
        }, 60000)
        return () => clearInterval(intervalId); //This is important
    }, [])

    useEffect(() => {
        fetchSignals('').then(data => {
            signal.setSignals(data)
        })
    }, [])


    let ogTitle = 'Signals list'
    let h1 = 'Signals list'
    let ogDescription = ''
    let ogImage = 'og_image1.png'

    return (
        <Container>
            <Helmet>
                <title>{ogTitle}</title>
                <meta name="description" content={ogDescription} />
                <meta name="twitter:card" content={ogImage} />
                <meta name="twitter:title" content={ogTitle} />
                <meta name="twitter:description" content={ogDescription} />
                <meta name="twitter:image" content={ogImage} />
                <meta property="og:title" content={ogTitle} />
                <meta property="og:description" content={ogDescription} />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:site_name" content="Crypto Twitter Analytics" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
            </Helmet>
            <Row className="mt-2">
                <Col>
                    <h1 style={{fontSize: '18px'}}>{h1}</h1>
                    {
                        /*
                         <SignalsList/>
                         */
                    }

                    <Footer/>
                </Col>
            </Row>
        </Container>
    );
});

export default SignalsPage;